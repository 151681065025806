<script setup lang="ts">
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const { modelValue, parentValue, handleChange, handleBlur } = useField(
  props,
  emit,
)

const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

const { castPlugin } = useFieldCast(props.node)

useFieldCache(props.node, parentValue)
</script>

<template>
  <ManagerField v-bind="props">
    <template #default="{ name, label }">
      <Tooltip
        class="bg-gray-950 text-xs"
        outer-class="grow flex"
        :content="node.tooltip ? node.tooltip : undefined"
        placement="top"
      >
        <FormKit
          v-model="modelValue"
          type="currency"
          currency="AUD"
          display-locale="en-AU"
          decimals="2"
          :name="name"
          :label="label"
          outer-class="w-full"
          label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
          :plugins="[castPlugin]"
          :min="node.min ?? undefined"
          :max="node.min ?? undefined"
          @input="handleChange"
          @blur="handleBlur"
        >
          <template v-if="node.suffix" #suffix>
            <span
              class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              {{ node.suffix }}
            </span>
          </template>
        </FormKit>
      </Tooltip>
    </template>
  </ManagerField>
</template>
